/* global contactModule:true */
/* global aaPhoneCountryCode:true */

contactModule.directive('aaFileModel', ['$parse', function ($parse) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var model = $parse(attrs.aaFileModel);
            var isMultiple = attrs.multiple;
            var modelSetter = model.assign;
            var values = [];
            element.bind('change', function () {
                angular.forEach(element[0].files, function (item) {
                    var value = {
                       // File Name 
                        name: item.name,
                        //File Size 
                        size: item.size,
                        //File URL to view 
                        url: URL.createObjectURL(item),
                        // File Input Value 
                        _file: item
                    };
                    values.push(value);
                });
                scope.$apply(function () {
                    if (isMultiple) {
                        modelSetter(scope, values);
                    } else {
                        modelSetter(scope, values[0]);
                    }
                });
            });
        }
    };
}]);

contactModule.directive('aaAirportLookup', [function () {
    return {
        restrict: 'E',
        link: function (scope, element, attrs) {
            contactModule.contactAA.initAirportLookup('.aaAirportLookup');
        }
    };
}]);

contactModule.directive('aaAutoComplete', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            $timeout(function () {
                contactModule.contactAA.initAutoComplete('.aaAutoComplete');
            }, 0);
        }
    };
}]);

contactModule.directive('aaPhoneWidget', ['$timeout', function ($timeout) {
    return {
        restrict: 'E',
        link: function (scope, element, attrs) {
            $timeout(function () {
                aaPhoneCountryCode.init();
            }, 0);
        }
    };
}]);

contactModule.directive('aaDatePicker', [function () {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var options = {
                minDate: -365,
                maxDate: +365,
                onSelect: function (selectedDate, event) {
                    $j(this).trigger('change');
                },
                onClose: function (dateText, inst) {
                    this.focus();
                    this.fixFocusIE = true;
                }
            };
            contactModule.contactAA.initDatePicker('.aaDatePicker', options);
            turnOffKeypadDateFieldForMobile();
        }
    }
}]);

contactModule.directive('departureDate', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
        	var options = {
                minDate: +0,
                maxDate: +331,
                onSelect: function (selectedDate, event) {
                    var departureDateId = attrs.id;
                    var returnDateId = attrs.id.replace('departure', 'return');
                    var departureDate = getDate(departureDateId);
                    var returnDate = getDate(returnDateId);

                    var returnDateElement = angular.element('#' + sanitizeId(returnDateId));
                    if (returnDate < departureDate) {
                        returnDateElement.datepicker("setDate", "");
                    }
                    returnDateElement.datepicker("option", "minDate", departureDate);
                    $j(this).trigger('change');
                },
                onClose: function (dateText, inst) {
                    this.focus();
                    this.fixFocusIE = true;
                }
            };
            $timeout(function () {
            	contactModule.contactAA.initDatePicker('.aaDatePicker', options);
            	turnOffKeypadDateFieldForMobile();
            }, 0);
        }
    };
}]);

contactModule.directive('returnDate', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var options = {
                minDate: +0,
                maxDate: +331,
                onSelect: function (selectedDate, event) {
                    $j(this).trigger('change');
                },
                onClose: function (dateText, inst) {
                    this.focus();
                    this.fixFocusIE = true;
                }
            };
            $timeout(function () {
                contactModule.contactAA.initDatePicker('.aaDatePicker', options);
                turnOffKeypadDateFieldForMobile();
            }, 0);
        }
    }
}]);

var sanitizeId = function (id) {
    return id.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]").replace(/\./g, '\\.');
};

var getDate = function (id) {
    return angular.element('#' + sanitizeId(id)).datepicker('getDate');
};

var turnOffKeypadDateFieldForMobile = function () {
    var isMobileDevice = $device.touch;
    if (isMobileDevice) {
        var datepickerElement = angular.element(".aaDatePicker");
        datepickerElement.attr("readonly", "readonly");
    }
}



